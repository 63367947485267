<template>
  <div>
    <hero-bar>
      Subscription
    </hero-bar>
    <section class="section is-main-section pb-2">
      <card-widget
        class="tile is-child"
        type="is-info"
        icon="fa-tachometer-alt"
        :number="organisation.maxConcurrentUsers"
        label="Max Concurrent Active Devices"
      />
    </section>
    <section class="section is-main-section pt-1 pb-2">
      <subscription-notice class="tile is-child" />
    </section>
    <section class="section is-main-section pt-1 pb-2">
      <subscription-form class="tile is-child" />
    </section>
    <section class="section is-main-section pt-1">
      <subscription-faq class="tile is-child" />
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
import SubscriptionForm from '@/components/SubscriptionForm'
import SubscriptionNotice from '@/components/SubscriptionNotice'
import SubscriptionFaq from '@/components/SubscriptionFaq'
import CardWidget from '@/components/CardWidget'

// import UserAvatar from '@/components/UserAvatar'
export default {
  name: 'Subscription',
  components: {
    HeroBar,
    SubscriptionNotice,
    SubscriptionForm,
    SubscriptionFaq,
    CardWidget
  },
  computed: {
    profile () {
      return this.$store.state.profile
    },
    organisation () {
      return this.$store.state.organisation
    },
    email () {
      return this.$store.state.user.email
    },
    ...mapState(['userName', 'userEmail'])
  },
  mounted () {
    if (!this.profile.isRootAccount) {
      this.$buefy.toast.open({
        message: 'Subscription page only available to the Root Account',
        queue: false
      })
      this.$router.push({ name: 'home' })
    }

    const scr = document.createElement('script')
    scr.innerHTML = "gtag('event', 'conversion', {'send_to': 'AW-10814770953/EI3BCK7eqqADEIme8aQo'})"
    document.body.appendChild(scr)

    this.$mixpanel.track('page_visit', {
      distinct_id: this.$store.state.profile.organisationId,
      organisation_name: this.$store.state.organisation.name,
      user_email: this.$store.state.profile.email,
      page_name: this.$route.name
    })
  }
}
</script>

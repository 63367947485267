<template>
  <card-component title="Manage Subscription" icon="fa-bolt">
    <div v-show="organisation.maxConcurrentUsers < 5 || !organisation.hasChargebeeAccount">
      <div class="mb-2 has-text-weight-semibold">
        Select number of maximum concurrent active devices
      </div>
      <div v-if="pricingVersion === 3" class="buttons">
        <button
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-item-0="10-Concurrent-Player-v3-USD-Monthly"
          data-cb-item-0-quantity="1"
          class="button is-primary"
        >
        10 devices, USD 49/month
        </button>
        <button
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-item-0="60-Concurrent-Player-v3-USD-Monthly"
          data-cb-item-0-quantity="1"
          class="button is-primary"
        >
        60 devices, USD 79/month
        </button>
        <button
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-item-0="120-Concurrent-Player-v3-USD-Monthly"
          data-cb-item-0-quantity="1"
          class="button is-primary"
        >
        120 devices, USD 159/month
        </button>
        <button
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-item-0="150-Concurrent-Player-v2-USD-Monthly"
          data-cb-item-0-quantity="1"
          class="button is-primary"
        >
        150 devices, USD 195/month
        </button>
        <button
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-item-0="200-Concurrent-Players-v2-USD-Monthly"
          data-cb-item-0-quantity="1"
          class="button is-primary"
        >
        200 devices, USD 265/month
        </button>
        <button
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-item-0="250-Concurrent-Players---v2-USD-Monthly"
          data-cb-item-0-quantity="1"
          class="button is-primary"
        >
        250 devices, USD 330/month
        </button>
        <button
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-item-0="300-Concurrent-Players---v2-USD-Monthly"
          data-cb-item-0-quantity="1"
          class="button is-primary"
        >
        300 devices, USD 395/month
        </button>
        <a
          href="mailto:hello@playtours.app"
          @click="clickedMailTo"
          class="button is-primary"
        >
        <i class="fas fa-envelope mr-2"></i>
        > 300 devices? Let's Chat!
        </a>
      </div>
      <div v-if="pricingVersion === 2" class="buttons">
        <button
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-item-0="10-Concurrent-Player-v2-USD-Monthly"
          data-cb-item-0-quantity="1"
          class="button is-primary"
        >
        10 devices, USD 29/month
        </button>
        <button
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-item-0="20-Concurrent-Player-v2-USD-Monthly"
          data-cb-item-0-quantity="1"
          class="button is-primary"
        >
        20 devices, USD 39/month
        </button>
        <button
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-item-0="60-Concurrent-Player-v2-USD-Monthly"
          data-cb-item-0-quantity="1"
          class="button is-primary"
        >
        60 devices, USD 79/month
        </button>
        <button
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-item-0="120-Concurrent-Player-v2-USD-Monthly"
          data-cb-item-0-quantity="1"
          class="button is-primary"
        >
        120 devices, USD 159/month
        </button>
        <button
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-item-0="150-Concurrent-Player-v2-USD-Monthly"
          data-cb-item-0-quantity="1"
          class="button is-primary"
        >
        150 devices, USD 195/month
        </button>
        <button
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-item-0="200-Concurrent-Players-v2-USD-Monthly"
          data-cb-item-0-quantity="1"
          class="button is-primary"
        >
        200 devices, USD 265/month
        </button>
        <button
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-item-0="250-Concurrent-Players---v2-USD-Monthly"
          data-cb-item-0-quantity="1"
          class="button is-primary"
        >
        250 devices, USD 330/month
        </button>
        <button
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-item-0="300-Concurrent-Players---v2-USD-Monthly"
          data-cb-item-0-quantity="1"
          class="button is-primary"
        >
        300 devices, USD 395/month
        </button>
        <a
          href="mailto:hello@playtours.app"
          @click="clickedMailTo"
          class="button is-primary"
        >
        <i class="fas fa-envelope mr-2"></i>
        > 300 devices? Let's Chat!
        </a>
      </div>
      <div v-else-if="pricingVersion === 1" class="buttons">
        <button
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-item-0="5-Concurrent-Players-USD-Monthly"
          data-cb-item-0-quantity="1"
          class="button is-primary"
        >
        5 devices, USD 10/month
        </button>
        <button
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-item-0="10-Concurrent-Players-USD-Monthly"
          data-cb-item-0-quantity="1"
          class="button is-primary"
        >
        10 devices, USD 14/month
        </button>
        <button
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-item-0="20-Concurrent-Players-USD-Monthly"
          data-cb-item-0-quantity="1"
          class="button is-primary"
        >
        20 devices, USD 29/month
        </button>
        <button
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-item-0="50-Concurrent-Players-USD-Monthly"
          data-cb-item-0-quantity="1"
          class="button is-primary"
        >
        50 devices, USD 79/month
        </button>
        <button
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-item-0="100-Concurrent-Players-USD-Monthly"
          data-cb-item-0-quantity="1"
          class="button is-primary"
        >
        100 devices, USD 179/month
        </button>
        <button
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-item-0="150-Concurrent-Player-v2-USD-Monthly"
          data-cb-item-0-quantity="1"
          class="button is-primary"
        >
        150 devices, USD 195/month
        </button>
        <button
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-item-0="200-Concurrent-Players-v2-USD-Monthly"
          data-cb-item-0-quantity="1"
          class="button is-primary"
        >
        200 devices, USD 265/month
        </button>
        <button
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-item-0="250-Concurrent-Players---v2-USD-Monthly"
          data-cb-item-0-quantity="1"
          class="button is-primary"
        >
        250 devices, USD 330/month
        </button>
        <button
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-item-0="300-Concurrent-Players---v2-USD-Monthly"
          data-cb-item-0-quantity="1"
          class="button is-primary"
        >
        300 devices, USD 395/month
        </button>
        <a
          href="mailto:hello@playtours.app"
          @click="clickedMailTo"
          class="button is-primary"
        >
        <i class="fas fa-envelope mr-2"></i>
        > 300 devices? Let's Chat!
        </a>
      </div>
      <notification class="is-dark">
        <div>
          All our subscriptions are on a monthly basis.
          <br>
          You can cancel anytime or schedule your cancellation in advance.
        </div>
      </notification>
      <!-- <div class="mb-2 has-text-weight-semibold">
        Price comparison with our top competitors:
      </div>
      <div @click="open('https://loquiz.com/pricing/')" class="is-clickable">
        Loquiz - USD 53 (base amount) + 5.3/player (no unlimited devices)
        <i class="fas fa-external-link-alt ml-2"></i>
      </div>
      <div @click="open('https://www.goosechase.com/pricing')" class="is-clickable">
        GooseChase - USD 49.8 per player or team (no unlimited devices)
        <i class="fas fa-external-link-alt ml-2"></i>
      </div>
      <div @click="open('https://en.actionbound.com/license/business/1')" class="is-clickable">
        ActionBound - USD 6.4 per player (no unlimited devices)
        <i class="fas fa-external-link-alt ml-2"></i>
      </div> -->
      <!-- <div @click="open('https://locatify.com/plans-pricing/')" class="is-clickable">
        Locatify - USD 74 per day (unlimited devices only for a day)
        <i class="fas fa-external-link-alt ml-2"></i>
      </div> -->
    </div>
    <div v-show="organisation.hasChargebeeAccount">
      <notification class="is-light">
        <div>
          We use ChargeBee to offer a high level of security.
          <br>
          Your credit card details are handled directly on their platforms.
          <br>
          <br>
          When the pop-up window appears, enter <b>{{ email }}</b> as the email address.
        </div>
      </notification>
      <div class="has-text-centered">
        <button
          href="javascript:void(0)"
          data-cb-type="portal"
          class="button is-primary"
        >
          Click here to view billing history, change payment method or cancel subscription
        </button>
      </div>
    </div>
  </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import Notification from '@/components/Notification'

export default {
  name: 'SubscriptionForm',
  components: {
    CardComponent,
    Notification
  },
  data () {
    return {
    }
  },
  computed: {
    profile () {
      return this.$store.state.profile
    },
    organisation () {
      return this.$store.state.organisation
    },
    email () {
      return this.$store.state.user.email
    },
    pricingVersion () {
      if (!this.organisation) return 1
      const seconds = this.organisation.createdAt.seconds
      const nanoSeconds = this.organisation.createdAt.nanoseconds
      const timestamp = new Date(seconds * 1000 + Math.floor(nanoSeconds / 1000000))
      const april12 = new Date('2023-04-12')
      const jan5 = new Date('2024-01-05')
      if (timestamp < april12) {
        return 1
      } else if (timestamp < jan5) {
        return 2
      } else {
        return 3
      }
    }
  },
  methods: {
    open (url) {
      window.open(url)
    },
    handlePayment () {
      console.log('')
    },
    clickedMailTo () {
      this.$buefy.toast.open({
        message: 'hello@playtours.app copied to clipboard',
        type: 'is-primary',
        queue: false
      })
    }
  },
  mounted () {
    const el = document.createElement('script')
    el.onload = () => {
      window.Chargebee.registerAgain()
      const instance = window.Chargebee.getInstance()
      instance.setCheckoutCallbacks(this.handlePayment)
      const cart = instance.getCart()
      const customer = { email: this.email }
      cart.setCustomer(customer)
    }
    el.setAttribute('data-cb-site', 'playtoursapp')
    el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js')
    document.body.appendChild(el)
  }
}
</script>
